@import "@fontsource/titillium-web";
/*
  Color
*/
$body-color: #1f1f1f !default;
$dark: #000000;
$grey: #dcdbdb;
$info: #337AB7;
$light: #DCDBDB;
$primary: #29594d;
$secondary: #444444;
$tertiary: #d6dedb;
$theme-colors: map-remove($theme-colors, "light", "primary", "secondary");
$custom-colors: (
  "grey": $grey,
  "dark": $dark,
  "info": $info,
  "light": $light,
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
);
$theme-colors: map-merge($theme-colors, $custom-colors);
/*
  Buttons
*/
$btn-border-radius: $border-radius-lg;
/*
 Font
*/
$font-family-serif: "Titillium Web", Georgia, serif;
$font-family-sans-serif: "Titillium Web", Arial, Helvetica, sans-serif;
$font-family-monospace: "Titillium Web", "Courier New", Courier, monospace;
/*
  Footer
*/
$footer-bottom-background: #1c3d33;
$footer-top-background: $primary;
/*
  Form
*/
$form-group-margin-bottom: 1rem;
/*
  Header
*/
$header-center-bg-color: $primary;
$header-center-h2-size: 2rem;
$header-center-h3-size: 1rem;
$header-center-text-size: 1rem;
$header-slim-bg-color: #ffffff;
$header-slim-h-padding: 0;
$header-slim-text-color: $primary;
$header-slim-brand-text-size: 1rem;
$header-slim-button-color: $primary;
$header-slim-button-hover-color: #01283a;
/*
  Navigation
*/
$navigation-link-color: $primary;
$navigation-link-active-bar-color: $primary;
