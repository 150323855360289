.field-blocked {
  background-color: #d6dedb;
}
// AUTOCOMPLETE
.autocomplete-container {
  box-shadow: none !important;
}

.autocomplete-container .input-container input {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='hsl%280, 0%, 15%%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: none !important;
  border-bottom: 1px solid hsl(210, 17.6470588235%, 43.35%) !important;
  border-radius: 0 !important;
  padding: 0.375rem 0.5rem !important;
  outline: 0 !important;
  height: 2.5rem !important;
  width: 100% !important;
  box-shadow: none !important;
  transition: 0.2s ease-out !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: hsl(0, 0%, 10%) !important;
  background-color: hsl(0, 0%, 100%) !important;
}

.autocomplete-container .suggestions-container ul li a {
  padding: 14px 15px;
  display: block;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  color: #000000 !important;
  font-size: 1rem !important;
}

.autocomplete-container .input-container input::placeholder {
  // color: hsl(0, 0%, 10%) !important;
  color: hsl(210, 17%, 44%) !important;
}

.autocomplete-container .input-container input:disabled {
  background-color: $tertiary !important;
}

// BUTTONS

// .btn:disabled {
// box-shadow: initial !important;
// }
.btn-badge {
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  color: #000000;
  background-color: $light;
  vertical-align: middle;
  border: 0 solid transparent;
  border-radius: 8px;
}

.btn.btn-primary {
  color: #fff !important;
  background-color: #29594d !important;
}

// CARD
.card:after {
  margin-top: 0 !important;
}

// FORM
.form-control:disabled, .form-control[readonly] {
  background-color: $tertiary;
  opacity: 1;
}

// FILL
.fill-primary {
  fill: $primary !important;
}

.input-search {
  position: relative;
  input[type="search"] {
    border-style: solid;
    border-width: 1px;
    border-color: $primary;
    border-radius: 3px;
  }
  .input-group-text {
    background-color: transparent;
    position: absolute;
    right: 0;
    border: none;
    icon {
      background-color: transparent;
      z-index: 10;
    }
  }
}

// MODAL
.modal-header {
  justify-content: start;
}
.modal-footer {
  justify-content: start;
}

// NAVBAR
.navbar-nav {
  li {
    a.nav-link {
      font-weight: 600 !important;
    }
  }
}

// PAGINATION
.pagination .page-item.active .page-link {
  border: solid $primary !important;
  color: $primary !important;
}

// PAGINE STATICHE
.dms-empty-state .h-content {
  min-height: auto;
}
@media (width: 1024px) {
  .dms-empty-state .h-content {
    height: 55rem;
  }
}
@media (min-width: 992px) {
  .dms-empty-state .h-content {
    min-height: 45rem;
  }
}

@media (min-width: 768px) {
  .dms-empty-state .h-content {
    min-height: 35rem;
  }
}

// STEPPER
@media (min-width: 576px) {
  .steppers .steppers-header ul li h1 {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 992px) {
  .steppers .steppers-nav button .icon {
    display: inline-block !important;
  }

  .steppers .steppers-header li.active:after {
    width: 100% !important;
  }
}

.steppers-content {
  padding-bottom: 106px;
  position: relative;
  min-height: 400px;
}

.steppers-content .steppers-nav {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.steppers .steppers-nav {
  height: 106px !important;
}

.steppers .steppers-header ul li h1 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
}

.steppers .steppers-index span.active {
  text-decoration: none;
}
